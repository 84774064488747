import PlaceType from './place.type';

const initialState = {
  featuredPlaces: [],
  otherPlaces: [],
  allPlaces: [],
};

const placeReducer = (state = initialState, action) => {
  switch (action.type) {
    case PlaceType.LOAD:
      const {featuredPlaces, otherPlaces} = action?.payload;

      return {
        ...state,
        featuredPlaces,
        otherPlaces,
        allPlaces: [...featuredPlaces, ...otherPlaces],
      };

    default:
      return state;
  }
};

export default placeReducer;
