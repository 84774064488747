import AirlineType from './airline.type';

const initialState = {
  airlineBrandList: [],
};

const AirlineReducer = (state = initialState, action) => {
  // console.log('state: ', state);
  // console.log('action: ', action);
  //AirlineType.SET_AIRLINE_BRAND_LIST = airline/set_airline_brand_list
  switch (action.type) {
    case AirlineType.SET_AIRLINE_BRAND_LIST: {
      const {airlineBrandList} = action?.payload;
      // console.log('action?.payload: ', action?.payload);
      return {
        ...state,
        airlineBrandList,
      };
    }
    default:
      // console.log('default state: ', state);
      return state;
  }
};

export default AirlineReducer;
