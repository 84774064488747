
import { Grid } from "antd"

const { useBreakpoint } = Grid

export const useCommon = () => {
  // breakpoint
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint()
  let breakpoint = ""
  if (xs && !sm && !md && !lg && !xl && !xxl) breakpoint = "xs"
  if (sm && !md && !lg && !xl && !xxl) breakpoint = "sm"
  if (md && !lg && !xl && !xxl) breakpoint = "md"
  if (lg && !xl && !xxl) breakpoint = "lg"
  if (xl && !xxl) breakpoint = "xl"
  if (xxl) breakpoint = "xxl"
  const isSmallScreen = breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md"

  return {
    currentBreakpoint: breakpoint,
    isSmallScreen,
  }
}
