import React, {lazy, memo} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import ProtectedRoute from './components/ProtectedRoute';
import {useContext} from 'react';
import {ConfigContext} from './ConfigProvider';
import {useMemo} from 'react';

const Home = lazy(() => import('./views/Home'));
const HomePage = lazy(() => import('./views/HomePage'));
const TicketsList = lazy(() => import('./views/TicketsList'));
const PostDetailPage = lazy(() => import('./views/PostDetailPage'));
const ResetPasswordPage = lazy(() => import('./views/ResetPasswordPage'));
const ChangePasswordPage = lazy(() => import('./views/ChangePasswordPage'));
const UserProfile = lazy(() => import('./views/Profile'));
const UpdateProfilePage = lazy(() => import('./views/UpdateProfilePage'));
const RegisterPage = lazy(() => import('./views/RegisterPage'));
const LoginPage = lazy(() => import('./views/LoginPage'));
const ForgotPasswordPage = lazy(() => import('./views/ForgotPasswordPage'));
const BookedTickets = lazy(() => import('./views/BookedTickets'));
const BookedTicketDetails = lazy(() => import('./views/BookedTicketDetails'));
const SelectBank = lazy(() => import('./views/RegisterPage/components/SelectBank'));
const BookedManager = lazy(() => import('./views/BookedManager'));
const NewsPromotion = lazy(() => import('./views/Home/components/NewsPromotion'));

const RouterPage = () => {
  const {setting} = useContext(ConfigContext);
  const PageDict = useMemo(
    () => ({
      LANDING: Home,
      FLIGHT: HomePage,
    }),
    [Home, HomePage]
  );

  const DefaultPage = useMemo(() => {
    const defaultPage = setting?.DEFAULT_PAGE;
    return PageDict[defaultPage] || Home;
  }, [setting]);

  return (
    <>
      <Switch>
        <Route path="/" exact component={DefaultPage} />
        <Route path="/flight" exact component={HomePage} />
        <Route path="/news-promotion/:promotionId" exact component={NewsPromotion} />
        <Route path="/news/:type" exact component={PostDetailPage} />
        <Route path="/flight/tickets" exact component={TicketsList} />
        <Route path="/reset-password" exact component={ResetPasswordPage} />
        <ProtectedRoute path="/change-password" exact component={ChangePasswordPage} />
        <ProtectedRoute path="/profile" exact component={UserProfile} />
        <ProtectedRoute path="/profile/:type" exact component={UpdateProfilePage} />
        <AuthRoute path="/register/" exact component={RegisterPage} />
        <Route path="/bank/:type" exact component={SelectBank} />
        <AuthRoute path="/login" exact component={LoginPage} />
        <AuthRoute path="/forgot-password" exact component={ForgotPasswordPage} />
        <ProtectedRoute path="/booked-tickets" exact component={BookedTickets} />
        <ProtectedRoute path="/booked-manager" exact component={BookedManager} />
        <Route path="/booked-tickets/:id" exact component={BookedTicketDetails} />
        <Redirect to="/" />
        {/* <Route path="*" render={() => <Redirect to="/" />} /> */}
      </Switch>
    </>
  );
};

export default memo(RouterPage);
