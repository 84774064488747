import react, {createContext, useState, useEffect} from 'react';
import UserApi from './services/Api/UserApi';

export const ConfigContext = createContext({});

const ConfigProvider = ({children}) => {
  const [dataConfig, setDataConfig] = useState(
    localStorage.getItem('dataConfig')
      ? JSON.parse(localStorage.getItem('dataConfig'))
      : {
          whyProperties: [],
          linkSocial: [],
          linkReference: [],
          setting: [],
          hotNews: [],
          isLoading: true,
        }
  );
  useEffect(() => {
    async function fetchSettingConfig() {
      try {
        const response = await UserApi.accounts.getSettingConfig();
        const rs = response?.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {});
        setDataConfig(old => ({...old, setting: rs}));
      } catch (error) {
        console.error(error);
      }
    }
    fetchSettingConfig();
  }, []);
 
  const fetchLinkSocial = async id => {
    try {
      const response = await UserApi.accounts.getLinkSocial({id: id});
      const newsTypeId = response?.items[0]?.id;
      if (newsTypeId) {
        fetchNewsReference(id, newsTypeId, true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNewsType = async id => {
    try {
      const response = await UserApi.accounts.getNewsType({id: id});
      const newsTypeId = response?.items[0]?.id;
      if (response) {
        fetchNews(id, newsTypeId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHotNews = async id => {
    try {
      const response = await UserApi.accounts.getHotNews({id: id});
      const newsTypeId = response?.items[0]?.id;
      if (response) {
        fetchHotNewsList(id, newsTypeId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHotNewsList = async (siteId, newsTypeId) => {
    UserApi.accounts
      .getHotNewsList({
        siteId: siteId,
        newsTypeId: newsTypeId,
      })
      .then(response => {
        setDataConfig(old => ({
          ...old,
          hotNews: response.items,
          isLoading: false,
        }));
      });
  };

  const fetchSiteId = async () => {
    try {
      const response = await UserApi.accounts.getSiteId(window.location.hostname);
      if (response) {
        fetchNewsType(response?.id);
        fetchLinkSocial(response?.id);
        fetchHotNews(response?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSiteIdReference = async () => {
    try {
      const response = await UserApi.accounts.getSiteId(window.location.hostname);
      if (response) {
        fetchNewsTypeReference(response?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNewsTypeReference = async id => {
    try {
      const response = await UserApi.accounts.getNewsTypeReference({id: id});
      const newsTypeId = response?.items[0]?.id;

      if (response) {
        fetchNewsReference(id, newsTypeId, false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNews = (siteId, newsTypeId) => {
    UserApi.accounts
      .getNews({
        siteId: siteId,
        newsTypeId: newsTypeId,
      })
      .then(response => {
        // console.log(response);
        // setWhyProperties(response);
        setDataConfig(old => ({
          ...old,
          whyProperties: response,
          isLoading: false,
        }));
        // setIsLoading(false);
      });
  };

  const fetchNewsReference = (siteId, newsTypeId, isSocial) => {
    UserApi.accounts
      .getNews({
        siteId: siteId,
        newsTypeId: newsTypeId,
      })
      .then(response => {
        // if (isSocial) setLinkSocial(response?.items ?? []);
        // else setLinkReference(response);
        // setIsLoading(false);
        if (isSocial) {
          setDataConfig(old => ({
            ...old,
            linkSocial: response?.items ?? [],
            isLoading: false,
          }));
        } else {
          setDataConfig(old => ({
            ...old,
            linkReference: response,
            isLoading: false,
          }));
        }
      });
  };

  useEffect(() => {
    fetchSiteId();
    fetchSiteIdReference();
  }, []);

  useEffect(() => {
    document.body.style.setProperty('--primary-color', dataConfig.setting?.PRIMARY_COLOR);
    document.body.style.setProperty('--secondary-color', dataConfig.setting?.SECONDARY_COLOR);
    document.body.style.setProperty('--contrast-color', dataConfig.setting?.CONSTRAST_COLOR);
    // set title document
    document.title = dataConfig.setting?.SITE_NAME;
    // set favicon document
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = dataConfig.setting?.FAVICON;

    // set logo apple-touch-icon
    var linkIcon = document.querySelector("link[rel~='apple-touch-icon']");
    if (!linkIcon) {
      linkIcon = document.createElement('link');
      linkIcon.rel = 'apple-touch-icon';
      document.head.appendChild(linkIcon);
    }
    linkIcon.href = dataConfig.setting?.FAVICON;

    // set name and short name for PWA
    var meta1 = document.querySelector("meta[name='application-name']");
    if (!meta1) {
      meta1 = document.createElement('meta');
      meta1.name = 'application-name';
      document.head.appendChild(meta1);
    }
    meta1.content = dataConfig.setting?.SITE_NAME;

    var meta2 = document.querySelector("meta[name='apple-mobile-web-app-title']");
    if (!meta2) {
      meta2 = document.createElement('meta');
      meta2.name = 'apple-mobile-web-app-title';
      document.head.appendChild(meta2);
    }
    meta2.content = dataConfig.setting?.SITE_NAME;

    localStorage.setItem('dataConfig', JSON.stringify(dataConfig));
  }, [dataConfig]);

  return <ConfigContext.Provider value={dataConfig}>{children}</ConfigContext.Provider>;
};
export default ConfigProvider;
