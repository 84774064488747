import TicketListType from './ticketlist.type';

const ticketListReducer = (state = {}, action) => {
  switch (action.type) {
    case TicketListType.TICKETS:
      return {
        ...state,
        tickets: action.payload,
      };
    default:
      return { ...state };
  }
};

export default ticketListReducer;
