const LocalStorageConstant = {
  USER_INFO: 'userProfile',
  REFRESH_TOKEN: 'ApiTokenKey',
  ACCESS_TOKEN: 'ApiTokenInfo',
  USER_INPUT: 'USER_SITE_USER_INPUT',
  SITE_KEY: 'SITE_KEY',
  SITE_ID: 'SITE_ID',
};

export default LocalStorageConstant;
