import React, { Suspense, memo, useState } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import { Skeleton, notification } from 'antd';
// import { onMessageListener, fetchToken } from './firebase'

import './configs/i18n';
import 'antd/dist/antd.css';
import './App.css';
import './style.scss';
import ScrollToTop from './hooks/ScrollToTop';
import { NotiProvider } from './store/notification';
import { StepSearchProvider } from './store/stepSearch';
import ConfigProvider from './ConfigProvider';
import RouterPage from './RouterPage';


const App = () => {
  const [noti, setNoti] = useState([])
  // fetchToken(({ payload }) => {
  //   localStorage.setItem('notiToken', payload)
  //   console.log('payload notiToken: ', payload);
  // });
  // const popNoti = (message, description) => notification?.info({
  //   message,
  //   description,
  //   placement: 'topRight',
  //   duration: 6,
  //   style: { borderRadius: 6 }
  // })
  // onMessageListener().then(payload => {
  //   console.log('payload: ', payload);
  //   popNoti(payload?.notification?.title, payload?.notification?.body)
  //   setNoti(old => ([...old, { title: payload?.notification?.title, body: payload?.notification?.body }]))
  // }).catch(err => console.log('failed: ', err));

  return (
    <Provider store={store}>
      <ConfigProvider>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Skeleton active />}>
            <Router>
              <NotiProvider
                noti={noti}
                setNoti={setNoti}
              >
                <StepSearchProvider>
                  <ScrollToTop />
                  <RouterPage />
                </StepSearchProvider>
              </NotiProvider>
            </Router>
          </Suspense>
        </PersistGate>
      </ConfigProvider>
    </Provider>
  );
};

export default memo(App);
