import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

window['react'] = React;
window['react-dom'] = ReactDOM;
const rootElement = document.getElementById('root');
ReactDOM.render(
  // <StrictMode>
  <App />
  // </StrictMode>,
  , rootElement
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
