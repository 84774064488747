import LocalStorageConstant from '../utils/constants/local-storage.constant'
import LocalStorage from '../services/LocalStorage';
import TokenApi from '../services/Api/TokenApi';
import UserDispatcher from '../store/user/user.dispatcher';
import { useHistory } from 'react-router-dom';


const useUser = () => {
  const history = useHistory();
    const isLoggedIn = () => {
        const token = LocalStorage.getItem(LocalStorageConstant.ACCESS_TOKEN);
        const rfToken = LocalStorage.getItem(LocalStorageConstant.REFRESH_TOKEN);
        const userInfo = LocalStorage.getItem(LocalStorageConstant.USER_INFO);
        if (token && rfToken && userInfo) return true;
        return false;
    }
    const logout = () => {
        TokenApi.token
          .logOutToken()
          .then(() => {
            LocalStorage.removeItem(LocalStorageConstant.USER_INFO);
            LocalStorage.removeItem(LocalStorageConstant.ACCESS_TOKEN);
            LocalStorage.removeItem(LocalStorageConstant.REFRESH_TOKEN);
            history.push('/');
            UserDispatcher.logOutUser();
          })
          .catch(err => {
            console.log(err);
          });
    }
    return {
        isUserLogin: isLoggedIn(),
        logout
    }
    
}
export default useUser