import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {combineReducers} from 'redux';

import userReducer from './user/user.reducer';
import placeReducer from './place/place.reducer';
import queryReducer from './query/query.reducer';
import menuReducer from './menu/menu.reducer';
import menuItemsReducer from './menu-items/menu-items.reducer';
import cheapReducer from './ticket-cheap/ticket-cheap.reducer';
import inforReducer from './infor/infor.reducer';
import airportReducer from './airport/airport.reducer';
import airlineReducer from './airline/airline.reducer';
import ticketlistReducer from './ticketlist/ticketlist.reducer';

const persistConfig = {
  key: 'hatika',
  version: 1,
  storage,
  whitelist: ['user', 'place', 'menuItems', 'airline'],
};

const rootReducer = combineReducers({
  cheap: cheapReducer,
  user: userReducer,
  place: placeReducer,
  query: queryReducer,
  menu: menuReducer,
  menuItems: menuItemsReducer,
  infor: inforReducer,
  airport: airportReducer,
  airline: airlineReducer,
  ticketList: ticketlistReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // middleware: [logger],
});

export const persistor = persistStore(store);
