import ApiService, { TOKEN_BASE_URL } from '../index';

const tokenUrl = '/token';

const TokenApi = {
  token: {
    getToken: params => {
      return ApiService(TOKEN_BASE_URL).post(`${tokenUrl}`, { ...params, deviceToken: localStorage.getItem('notiToken') ?? '' });
    },
    getRefreshToken: params => {
      return ApiService(TOKEN_BASE_URL).post(`${tokenUrl}/refresh-token`, { ...params, deviceToken: localStorage.getItem('notiToken') ?? '' });
    },
    logOutToken: () => {
      return ApiService(TOKEN_BASE_URL).get(`${tokenUrl}/logout`, { deviceToken: localStorage.getItem('notiToken') ?? '' });
    },
  },
};

export default TokenApi;
