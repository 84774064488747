import TicketCheapType from "../ticket-cheap/ticket-cheap.type";
import INFOR_USER from "./infor.type";

const initialState = []

const inforReducer = (state = initialState, action) => {


    switch (action.type) {
        case INFOR_USER.INFOR_USER:
            return [
                ...state,
                ...action?.payload,
            ];
        default:
            return state;
    }

  }


export default inforReducer;
