import QueryType from './query.type';

const initialState = {
  params: {},
  departureParams: {},
  returnParams: {},
};

const queryReducer = (state = initialState, action) => {
  switch (action.type) {
    case QueryType.SET_PARAMS: {
      const {params} = action?.payload;

      return {
        ...state,
        params,
      };
    }

    case QueryType.SET_DEPARTURE_PARAMS: {
      const {params} = action?.payload;

      return {
        ...state,
        departureParams: params,
      };
    }

    case QueryType.SET_RETURN_PARAMS: {
      const {params} = action?.payload;

      return {
        ...state,
        returnParams: params,
      };
    }

    default:
      return state;
  }
};

export default queryReducer;
