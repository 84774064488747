import { Route, Redirect } from 'react-router-dom';
import { useUser } from '../../hooks';

function ProtectedRoute({ component: Component, ...rest }) {
  const { isUserLogin } = useUser()

  return (
    <Route
      {...rest}
      render={props => {
        if (!isUserLogin) {
          return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default ProtectedRoute;
