import TicketCheapType from './ticket-cheap.type';

const initialState = []

const cheapReducer = (state = initialState, action) => {
  switch (action.type) {
    case TicketCheapType.ADD:
      return [
        ...state,
        ...action?.payload,
      ];
    default:
      return state;
  }
};

export default cheapReducer;
