import UserType from './user.type';

const initialState = {
  user: {},
  roles: [],
  isLoggedIn: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserType.ROLE:
      return {
        ...state,
        roles: action.payload,
        isLoggedIn: true,
      };
    case UserType.LOGIN:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };

    case UserType.LOG_OUT:
      return {
        roles: [],
        user: {},
        isLoggedIn: false,
      };

    default:
      return state;
  }
};

export default userReducer;
