import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function AuthRoute({component: Component, ...rest}) {
  const {isLoggedIn} = useSelector(state => state?.user);

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn) {
          return <Redirect to={{pathname: '/', state: {from: props.location}}} />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default AuthRoute;
