import MenuType from './menu.type';

const initialState = {
  isMenuVisible: false,
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case MenuType.SHOW:
      return {
        isMenuVisible: true,
      };

    case MenuType.HIDE:
      return {
        isMenuVisible: false,
      };

    default:
      return state;
  }
};

export default menuReducer;
