import axios from 'axios';
import LocalStorage from '../LocalStorage';
import LocalStorageConstant from '../../utils/constants/local-storage.constant';
import TokenApi from './TokenApi';
import moment from 'moment';

export const USER_BASE_URL = process.env.REACT_APP_USER_BASE_URL;
export const BALANCE_BASE_URL = process.env.REACT_APP_BALANCE_BASE_URL;
export const TOKEN_BASE_URL = process.env.REACT_APP_TOKEN_BASE_URL;
export const FLIGHT_BASE_URL = process.env.REACT_APP_FLIGHT_BASE_URL;
export const TICKET_BASE_URL = process.env.REACT_APP_TICKET_BASE_URL;
export const TICKET_BASE_URL2 = process.env.REACT_APP_TICKET_BASE_URL2;
export const BANK_BASE_URL = process.env.REACT_APP_BANK_BASE_URL;
export const AGENCY_BASE_URL = process.env.REACT_APP_AGENCY_BASE_URL;
export const SETTING_SERVICE_URL = process.env.REACT_APP_SETTING_SERVICE_URL;
export const NEWS_BASE_URL = process.env.REACT_APP_NEWS_BASE_URL;
export const FILE_BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
export const TRAVEL_BASE_URL = process.env.REACT_APP_TRAVEL_BASE_URL;
export const NOTIFICATION_BASE_URL = process.env.REACT_APP_NOTIFICATION_SERVICE_URL;

const getSiteKey = async () => {
  const url = window.location.hostname;
  const {data} = await axios.get(`${process.env.REACT_APP_AGENCY_BASE_URL}/sites/domain/${url}`);
  if (data?.result) {
    localStorage.setItem(LocalStorageConstant.SITE_KEY, data.result.siteKey);
    localStorage.setItem(LocalStorageConstant.SITE_ID, data.result.id);
    return data.result.siteKey;
  }
  return null;
};

const ApiService = urlBase => {
  const instance = axios.create({
    baseURL: urlBase,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate'
    },
  });

  const getRefreshToken = async () => {
    const refreshToken = LocalStorage.getItem(LocalStorageConstant.REFRESH_TOKEN);

    if(!refreshToken) {
      return null
    }
    const resp = await TokenApi.token.getRefreshToken({
      refreshToken: refreshToken?.token,
    });
    //sync storage with BE
    if (resp?.data) {
      const userInfo = _.pick(resp?.data, ['id', 'name', 'lastName', 'username']);
      const accessToken = _.pick(resp?.data, ['accessToken', 'expired']);
      const refreshToken = resp?.data?.refreshToken;

      LocalStorage.setItem(LocalStorageConstant.USER_INFO, userInfo);
      LocalStorage.setItem(LocalStorageConstant.ACCESS_TOKEN, accessToken);
      LocalStorage.setItem(LocalStorageConstant.REFRESH_TOKEN, refreshToken);

      return resp?.data?.refreshToken?.token;
    } else {
      LocalStorage.removeItem(LocalStorageConstant.USER_INFO);
      LocalStorage.removeItem(LocalStorageConstant.ACCESS_TOKEN);
      LocalStorage.removeItem(LocalStorageConstant.REFRESH_TOKEN);
      window.location.reload();
    }
    return null
  };

  instance.interceptors.request.use(async config => {
    const accessToken = LocalStorage.getItem(LocalStorageConstant.ACCESS_TOKEN);
    const isExpired = moment(accessToken?.expired).isBefore(moment());
    const SITEKEY = localStorage.getItem(LocalStorageConstant.SITE_KEY);
    let token = accessToken?.accessToken;
    if (!SITEKEY) {
      const newSiteKey = await getSiteKey();
      config.headers.siteKey = newSiteKey;
    } else {
      config.headers.siteKey = SITEKEY;
    }

    if (isExpired) {
      token = await getRefreshToken();
    }
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      delete config.headers['Authorization'];
    }

    return config;
  });

  instance.interceptors.response.use(
    response => {
      return Promise.resolve(response?.data?.result);
    },
    async function (error) {
      const originalRequest = error.config;

      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        const refreshToken = await getRefreshToken();

        if (refreshToken) {
          // noinspection AllyPlainJsInspection
          axios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`;
        }

        return instance(originalRequest);
      }

      return Promise.reject(error.response.data?.errorMessages || error.response.data?.errors);
    }
  );

  return instance;
};

export const PublicApiService = urlBase => {
  const instance = axios.create({
    baseURL: urlBase,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.response.use(
    response => {
      return response?.data?.result;
    },
    error => {
      if (error.code !== 'ERR_CANCELED') {
        return Promise.reject(error.response.data.errorMessages);
      }
    }
  );

  return instance;
};

export default ApiService;
