import React, {createContext, useState} from 'react';

export const StepSearchContext = createContext();
export const StepSearchProvider = ({children}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [finalTotal, setFinalTotal] = useState(0);

  return (
    <StepSearchContext.Provider value={{currentStep, setCurrentStep, finalTotal, setFinalTotal}}>
      {children}
    </StepSearchContext.Provider>
  );
};
