import {store} from '../index';
import UserType from './user.type';

const UserDispatcher = {
  setROles: user =>
    store.dispatch({
      type: UserType.ROLE,
      payload: user,
    }),
  logInUser: user =>
    store.dispatch({
      type: UserType.LOGIN,
      payload: user,
    }),
  logOutUser: () => {
    store.dispatch({
      type: UserType.LOG_OUT,
    });
  },
};

export default UserDispatcher;
