import ApiService, { USER_BASE_URL, BALANCE_BASE_URL, BANK_BASE_URL, NEWS_BASE_URL, AGENCY_BASE_URL, SETTING_SERVICE_URL, FILE_BASE_URL } from "../index";
import { FLIGHT_API_TOKEN } from "../../../utils/constants/auth.constant";

const accountUrl = "/accounts";

const UserApi = {
  accounts: {
    getUserInfo: () => {
      return ApiService(USER_BASE_URL).get(`${accountUrl}/logined/profile`);
    },
    getBalanceInfo: () => {
      return ApiService(BALANCE_BASE_URL).get(`/balances/me`);
    },
    searchBanks: params => {
      return ApiService(BANK_BASE_URL).get(`/banks`, {
        headers: {
          Authorization: `Bearer ${FLIGHT_API_TOKEN}`,
        },
      });
    },
    getBankInfo: params => {
      return ApiService(USER_BASE_URL).get(`${accountUrl}/${params?.guid}/banks`, {
        headers: {
          Authorization: `Bearer ${FLIGHT_API_TOKEN}`,
        },
      });
    },
    getSiteId: params => {
      return ApiService(AGENCY_BASE_URL).get(`/sites/domain/${params}`, {
        headers: {
        },
      });
    },
    getSettingConfig: params => {
      const keyNames = [
        'LOGO',
        'SITE_NAME',
        'CONTENT',
        'NUMBER_PHONE',
        'EMAIL',
        'FAVICON',
        'ENVIRONMENT',
        'PROVIDERS',
        'PROVIDERS_PAYMENT',
        'ADDRESS',
        'COMPANY_NAME',
        'PRIMARY_COLOR',
        'SECONDARY_COLOR',
        "TAX_CODE",
        'CONSTRAST_COLOR',
        'REQUIRED_LOGIN',
        "CERTIFICATE_LINK",
        'SHOW_MARKUP',
        "DEFAULT_PAGE",
        "BANKING",
        "BANKING_CONTENT"
      ].join('&keyNames=');
      return ApiService(SETTING_SERVICE_URL).get(`/settings/published/sites?keyNames=${keyNames}`, {
      });
    },
    getNewsType: params => {
      return ApiService(NEWS_BASE_URL).get(
        `/newstype/Site?siteId=${params?.id}&keywords=WHY_ME&IsMenu=false&Page=1&PageSize=10`
      );
    },
    getHotNews: params => {
      return ApiService(NEWS_BASE_URL).get(
        `/newstype/Site?siteId=${params?.id}&keywords=HOTNEWS&IsMenu=false&Page=1&PageSize=10`
      );
    },
    getHotNewsList: params => {
      return ApiService(NEWS_BASE_URL).get(
        `/news/Site?SiteId=${params?.siteId}&NewsTypeId=${params?.newsTypeId}&Page=1&PageSize=10`
      );
    },
    getNewsTypeReference: params => {
      return ApiService(NEWS_BASE_URL).get(
        `/newstype/Site?siteId=${params?.id}&keywords=LINK_FOOTER&IsMenu=false&Page=1&PageSize=10`
      );
    },
    getLinkSocial: params => {
      return ApiService(NEWS_BASE_URL).get(`/newstype/Site?siteId=${params?.id}&keywords=SOCIAL&IsMenu=false&Page=1&PageSize=10`);
    },
    getImages: () => {
      return ApiService(NEWS_BASE_URL).get(`/landing-page`);
    },
    getNewsPromotions: (newsId) => {
      return ApiService(NEWS_BASE_URL).get(`/news/${newsId}`);
    },
    getNews: params => {
      return ApiService(NEWS_BASE_URL).get(
        `/news/Site?SiteId=${params?.siteId}&NewsTypeId=${params?.newsTypeId}&Page=1&PageSize=10`
      );
    },
    getNewsContent: params => {
      return ApiService(NEWS_BASE_URL).get(`/news/${params?.newsId}/${params?.siteId}`);
    },
    forgotPassword: params => {
      return ApiService(USER_BASE_URL).post(`${accountUrl}/forgot-password`, params);
    },
    register: params => {
      return ApiService(USER_BASE_URL).post(`${accountUrl}/register`, params);
    },
    validation: params => {
      return ApiService(USER_BASE_URL).post(`${accountUrl}/register/validation`, params);
    },
    activate: params => {
      return ApiService(USER_BASE_URL).patch(`${accountUrl}/${params?.guid}/active`, {
        userName: params?.userName,
        token: params?.token,
      });
    },
    resetPassword: params => {
      return ApiService(USER_BASE_URL).put(`${accountUrl}/${params?.guid}/reset-password`, {
        password: params?.password,
        token: params?.token,
      });
    },
    changePassword: params => {
      return ApiService(USER_BASE_URL).put(`${accountUrl}/${params?.guid}/change-password`, {
        username: params?.username,
        oldPassword: params?.oldPassword,
        newPassword: params?.newPassword,
      });
    },
    changeUserInfo: params => {
      return ApiService(USER_BASE_URL).put(`${accountUrl}/${params?.guid}/profile`, {
        lastName: params?.lastName,
        name: params?.name,
        address: params?.address,
        email: params?.email,
        phoneNumber: params?.phoneNumber,
        profile: params?.profile,
        gender: params?.gender,
        dateOfBirth: params?.dateOfBirth,
        avatar: params?.avatar,
      });
    },
    changeBankInfo: params => {
      return ApiService(USER_BASE_URL).put(`${accountUrl}/${params?.userGuid}/banks/${params?.bankGuid}`, {
        bankName: params?.bankName,
        accountNumberBank: params?.accountNumberBank,
        branchBank: params?.branchBank,
        guid: params?.guid,
        bankId: params?.bankId,
      });
    },
    uploadFiles: params => {
      return ApiService(FILE_BASE_URL).post(`/files`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
};

export default UserApi;
