import AirportType from './airport.type';
import _ from 'lodash';

const initialState = {airportList: []};

const airportReducer = (state = initialState, action) => {
  switch (action.type) {
    case AirportType.FETCH_AIRPORT_LIST:
      return {...state, airportList: action?.payload};
    default:
      return state;
  }
};

export default airportReducer;
