import React, {useEffect, useState} from 'react';

const useResponsive = xWidth => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    if (window.innerWidth > 1200) {
      document.body.style.backgroundColor = 'rgb(244, 244, 246)';
    } else {
      document.body.style.backgroundColor = 'white';
    }
  }, [window]);
  return width > 1200 ? Math.floor(width / (width / 600) / xWidth) - 1 ?? 1 : Math.floor(width / xWidth) ?? 1;
};

export default useResponsive;
