import MenuItemsType from './menu-items.type';

const initialState = {
  menuItems: [],
  menuIdClicked:'',
  siteId:''
};

const menuItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MenuItemsType.LOAD:
      return {
        ...state,
        menuItems: action?.payload,
      };
      case MenuItemsType.CLICK:
      return {
        ...state,
        menuIdClicked: action?.payload,
      };
      case MenuItemsType.GET:
      return {
        ...state,
        siteId: action?.payload,
      };

    default:
      return state;
  }
};

export default menuItemsReducer;
